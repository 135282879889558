import axios from '@axios'

export default {
  namespaced: true,
  state: {
    // Categories
    respDataCateg: [],
    // formSurvey
    respDataForm: [],
    // CarAcc
    respDataCarAcc: [],
  },
  getters: {
  },
  mutations: {
    // Categories
    GET_CATEG(state, payload) {
      state.respDataCateg = payload
    },
    GET_CATEG_ADD(state, payload) {
      state.respDataCategForAdd = payload
    },
    ADD_CATEG(state, payload) {
      state.respDataCateg.data.push(payload)
    },
    EDIT_CATEG(state, item) {
      const productIndex = state.respDataCateg.data.findIndex(data => data._id === item._id)
      Object.assign(state.respDataCateg.data[productIndex], item)
    },
    DELETE_CATEG(state, id) {
      const ItemIndex = state.respDataCateg.data.findIndex(data => data._id === id)
      state.respDataCateg.data.splice(ItemIndex, 1)
    },
    // formSurvey
    GET_FORM(state, payload) {
      state.respDataForm = payload
    },
    ADD_FORM(state, payload) {
      state.respDataForm.data.unshift(payload)
    },
    VIEW_FORM(state, payload) {
      state.respDataForm = payload
    },
    DELETE_FORMSURVEY(state, id) {
      const itemIndex = state.respDataForm.data.findIndex(data => data._id === id)
      state.respDataForm.data.splice(itemIndex, 1)
    },
    VIEW_CHILD(state, payload) {
      state.respDataForm = [payload.data]
    },
    ADD_CHILD(state, payload) {
      if (state.respDataForm !== undefined) {
        state.respDataForm.unshift(payload)
      } else {
        state.respDataForm = [payload]
      }
    },
    // DELETE(state, id) {
    //   const ItemIndex = state.respData.data.findIndex(data => data._id === id)
    //   state.respData.data.splice(ItemIndex, 1)
    // },
    EDIT_FORM(state, item) {
      const productIndex = state.respDataForm.data.findIndex(data => data._id === item._id)
      Object.assign(state.respDataForm.data[productIndex], item)
    },
    // Accessories
    GET_CARACC(state, payload) {
      state.respDataCarAcc = payload
    },
  },
  actions: {
    // FormSurvey
    getFormSurvey({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/formSurvey/getFormSurvey?pagesize=${item.pageSize}&currentPage=${item.currentPage}`)
          .then(response => {
            console.log(response.data)
            commit('GET_FORM', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addFormSurvey({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/root/formSurvey/addFormSurvey', item)
          .then(response => {
            console.log(response.data.data)
            commit('ADD_FORM', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    viewFormSurvey({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/formSurvey/viewFormSurvey/${item}`)
          .then(response => {
            console.log(response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    viewChildren({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/formSurvey/viewFormSurvey/${item}`)
          .then(response => {
            console.log(response.data)
            commit('VIEW_CHILD', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // delete({ commit }, item) {
    //   return new Promise((resolve, reject) => {
    //     axios.delete(`/api/web/root/formSurvey/delete/${item}`)
    //       .then(response => {
    //         commit('DELETE', item)
    //         resolve(response)
    //       })
    //       .catch(error => {
    //         reject(error)
    //       })
    //   })
    // },
    editFormSurvey({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/root/formSurvey/editFormSurvey', item)
          .then(response => {
            commit('EDIT_FORM', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteFormSurvey({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/root/formSurvey/deleteForm/${item}`)
          .then(response => {
            commit('DELETE_FORMSURVEY', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addChildrenFormSurvey({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/root/formSurvey/addChildrenFormSurvey', item)
          .then(response => {
            console.log(response.data.data)
            commit('ADD_CHILD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // Accessories
    getAccessories({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/root/formSurvey/getAccessories')
          .then(response => {
            commit('GET_CARACC', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // Categories
    getCategoriesFormSurvey({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/formSurvey/getCategoriesFormSurvey?pagesize=${item.pageSize}&currentPage=${item.currentPage}`)
          .then(response => {
            console.log(response.data)
            commit('GET_CATEG', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCategoriesForAdd({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/formSurvey/getCategoriesForAdd?pagesize=${item.pageSize}&currentPage=${item.currentPage}`)
          .then(response => {
            console.log(response.data)
            commit('GET_CATEG', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addCategoriesFormSurvey({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/root/formSurvey/addCategoriesFormSurvey', item)
          .then(response => {
            console.log(response.data.data)
            commit('ADD_CATEG', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editCategoriesFormSurvey({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put(`/api/web/root/formSurvey/editCategoriesFormSurvey/${item._id}`, item)
          .then(response => {
            commit('EDIT_CATEG', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteCategoriesFormSurvey({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/root/formSurvey/deleteCategoriesFormSurvey/${item}`)
          .then(response => {
            commit('DELETE_CATEG', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

  },
}
